<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-6">

                    <label
                        :class="{'text-danger': states.menu_id === false}"
                        class="form-label"
                        for="menu-id"
                    >{{ $t('menus::menu_point_categories.columns.menu_id').ucFirst() }}</label
                    >
                    <select
                        id="menu-id"
                        v-model="model.menu_id"
                        :class="{'border-danger': states.menu_id === false}"
                        class="form-select"
                        name="menu_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('menus::menu_point_categories.placeholders.menu_id').ucFirst() }}
                        </option>
                        <option v-for="(menu, key) in menus" :key="key" :value="menu.id">
                            {{ menu.translation_key ? $t(menu.translation_key).ucFirst() : menu.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.menu_id === false}" class="invalid-feedback">{{
                            errors.menu_id
                        }}
                    </div>
                </div>
                <div class="col-lg-6">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('menus::menu_point_categories.columns.name').ucFirst() }}</label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('menus::menu_point_categories.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-4">

                    <label
                        :class="{'text-danger': states.translation_key === false}"
                        class="form-label"
                        for="translation-key"
                    >{{ $t('menus::menu_point_categories.columns.translation_key').ucFirst() }}</label
                    >
                    <input
                        id="translation-key"
                        v-model="model.translation_key"
                        :class="{'border-danger': states.translation_key === false}"
                        :placeholder="$t('menus::menu_point_categories.placeholders.translation_key').ucFirst()"
                        class="form-control"
                        list="translation-list"
                        name="translation_key"
                        type="text"
                    />
                    <div :class="{'d-block': states.translation_key === false}" class="invalid-feedback">
                        {{ errors.translation_key }}
                    </div>
                    <datalist id="translation-list">
                        <option v-for="(translation, key) in translations" :key="key" :value="translation">{{}}</option>
                    </datalist>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.icon === false}"
                        class="form-label"
                        for="translation-key"
                    >{{ $t('menus::menu_point_categories.columns.icon').ucFirst() }}</label
                    >
                    <b-input-group>
                        <b-input-group-text class="cursor-pointer p-0">
                            <icon-input
                                v-model="model.icon"
                                :error="errors.icon"
                                :placeholder="$t('menus::menu_point_categories.placeholders.icon').ucFirst()"
                                :state="states.icon"
                                class="cursor-pointer"
                            >
                            </icon-input>
                        </b-input-group-text>
                        <input :value="model.icon" class="form-control" readonly type="text">
                        <b-input-group-text v-if="model.icon" class="p-0 bg-white">
                            <i :class="model.icon" class="py-1 px-3"></i>
                        </b-input-group-text>
                    </b-input-group>
                </div>
                <div class="col-lg-4">
                    <label
                        class="form-label"
                        for="authentication_required"
                    >{{ $t('menus::menu_point_categories.columns.authentication_required').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <input id="authentication_required" v-model="model.authentication_required"
                               class="form-check-input float-none mx-3" type="checkbox">
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-12">

                    <label
                        :class="{'text-danger': states.description === false}"
                        class="form-label"
                        for="description"
                    >{{ $t('menus::menu_point_categories.columns.description').ucFirst() }}</label
                    >
                    <textarea
                        id="description"
                        v-model="model.description"
                        :class="{'border-danger': states.description === false}"
                        :placeholder="$t('menus::menu_point_categories.placeholders.description').ucFirst()"
                        class="form-control"
                        rows="3"
                    ></textarea>
                    <div :class="{'d-block': states.description === false}" class="invalid-feedback">
                        {{ errors.description }}
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import IconInput from "../elements/icon-input.vue";

export default {
    name: "MenuPointCategoryForm",

    emits: ['saved'],

    components: {IconInput},

    props: {
        menuPointCategory: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'menuPointCategory/loading',
            'states': 'menuPointCategory/states',
            'errors': 'menuPointCategory/errors',
            'translations': 'trans/keys',
            'menus': 'menu/all',
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: JSON.parse(JSON.stringify(this.menuPointCategory)),
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            return this.$store.dispatch(`menuPointCategory/${method}`, this.model).then(saved => {
                this.model = JSON.parse(JSON.stringify(saved))
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.' + '/menu-system/menu-point-categories/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },
    },

    watch: {
        menuPointCategory: function () {
            this.model = JSON.parse(JSON.stringify(this.menuPointCategory))
        },
    },

    created() {
        // console.log(this.menuPointCategory)
    }
}
</script>

<style scoped>

</style>
